<template>
    <div :class="['w-full flex', toolTip ? 'flex-row' : 'flex-col', $attrs.class]">
        <CoreInput
            v-bind="$attrs"
            :name="$attrs.name || $attrs.id || $attrs.label || $attrs.placeholder"
            label-wrapper-classes="flex items-center justify-between mb-1"
            label-classes="block text-gray-900 dark:text-gray-300 text-sm font-medium leading-6"
            input-wrapper-classes="relative rounded-md shadow-sm"
            :input-classes="`${defaultInputClasses} ${inputClasses}`"
            input-invalid-classes="ring-red-600 ring-2"
            error-classes="mt-1 text-sm text-red-600"
            :type="(showPassword) ? 'text' : type"
            class="w-full">
            <template
                v-for="(_, slot) in $slots"
                #[slot]="scope">
                <slot
                    :name="slot"
                    v-bind="{...scope}" />
            </template>
            <template
                v-if="$slots.inputHelper"
                #inputHelper>
                <slot name="inputHelper" />
            </template>
            <template
                v-if="inputIconLocal || $slots.inputIcon"
                #inputIcon>
                <div
                    :class="'absolute inset-y-0 ' + inputIconPosition + '-0 flex items-center pl-3 pr-3'"
                    @click="inputIconClick">
                    <slot name="inputIcon">
                        <Icon
                            :name="inputIconLocal"
                            :class="inputIconClass" />
                    </slot>
                </div>
            </template>
        </CoreInput>
        <div
            v-if="toolTip"
            class="pl-1 flex-shrink">
            <BasePopover
                size="sm"
                :position="toolTip === true ? 'left' : toolTip">
                <template #trigger>
                    <div class="flex items-center h-9 mt-7">
                        <Icon
                            v-if="toolTip"
                            name="heroicons:question-mark-circle"
                            class="h-5 w-5 text-primary-500 hover:bg-primary-500 hover:text-white hover:rounded-full" />
                    </div>
                </template>
                <template #default>
                    <span class="text-neutral-500 dark:text-gray-300 mt-2">
                        {{ description }}
                    </span>
                </template>
            </BasePopover>
        </div>
        <div
            v-if="!toolTip"
            class="text-sm mt-1">
            <span
                v-if="description"
                class="text-neutral-500 dark:text-gray-300">
                {{ description }}
            </span>
        </div>
    </div>
</template>

<script setup>
defineOptions({
    inheritAttrs: false
})

const defaultInputClasses = `block w-full rounded-md py-1.5 border-0 ring-1 ring-inset ring-gray-300 text-gray-900 shadow-sm placeholder:text-gray-400
                             appearance-none rounded w-full py-2 px-3 leading-tight
                             focus:ring-primary focus:ring-2 focus:ring-inset sm:text-sm focus:outline-none focus:shadow-outline
                             disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 disabled:shadow-none disabled:cursor-not-allowed
                             dark:text-gray-200 dark:placeholder:text-gray-500 disabled:dark:bg-gray-800 disabled:dark:text-gray-300 disabled:dark:ring-gray-400 disabled:dark:text-gray-400`

const props = defineProps({
    type: {
        type: String,
        default: 'text'
    },
    description: {
        type: String,
        default: null
    },
    toolTip: {
        type: Boolean || String,
        default: false,
        description: 'true == left || right || center'
    },
    inputClasses: {
        type: String,
        default: ''
    },
    inputIcon: {
        type: String,
        default: null
    },
    inputIconClass: {
        type: String,
        default: 'h-5 w-5 text-gray-500'
    },
    inputIconPosition: {
        type: String,
        default: 'right',
        description: 'left || right'
    }
})

const emits = defineEmits(['inputIconClick'])

const showPassword = ref(false)

const inputIconLocal = computed(() => {
    if (props.type === 'password') {
        return 'heroicons:eye' + (showPassword.value ? '-slash' : '')
    } else {
        return props.inputIcon
    }
})

function inputIconClick() {
    if (props.type === 'password') {
        showPassword.value = !showPassword.value
    } else {
        emits('inputIconClick')
    }
}
</script>